
import { defineComponent } from "vue";
import { NewsPost } from '../types';
import { fetchNewsPosts } from "@/apis/fetchNews";

export default defineComponent({
    name: "NewsItem",
    data(): { news: NewsPost[] } {
            return {
            news: [],
        };
},

mounted() {
    fetchNewsPosts().then((newsPosts) => {
        this.news = newsPosts;
    });
}
});
