
import { defineComponent, ref } from 'vue';
import router from '../router'
import axiosInstance from '../apis/axiosInstance';

interface LoginResponse {
    status: number;
    token: string;
}

export default defineComponent({
name: 'LoginComponent',
setup() {
const data = ref({
    email: '',
    password: ''
});

const handleSubmit = async () => {
    try {
        const response = await axiosInstance.post('/auth/login', {
            email: data.value.email,
            password: data.value.password,
            withCredentials: true
    });

    const responseData: LoginResponse = await response.data;
    const token = responseData.token;

    localStorage.setItem('token', token);

    // Redirect to the appropriate page based on the user role
    const role = router.currentRoute.value.meta.role;
    if (role === 'admin') {
        await router.push('/admin');
    } else {
        await router.push('/');
    }
    } catch (error) {
        console.error('Error:', error);
        await router.push('/login');
    }
}

    return {
        data,
        handleSubmit
    }
}
});
