
import router from '@/router';
import axiosInstance from '../apis/axiosInstance';
import { defineComponent, ref } from 'vue';

interface UserData {
    email: string;
    password: string;
    confirmPassword: string;
}

export default defineComponent({
name: 'RegisterComponent',
setup() {
    const data = ref<UserData>({
        email: '',
        password: '',
        confirmPassword: ''
    });
    const passwordMismatch = ref(false);

    const handleSubmit = async () => {
    if (data.value.password !== data.value.confirmPassword) {
        passwordMismatch.value = true;
        console.log('Wrong password');
        return;
    }

    const userInfo = {
        email: data.value.email,
        password: data.value.password
    };

    try {
        await axiosInstance.post('/auth/register', userInfo, {
        });

        await router.push('/login');
    } catch (error) {
        console.error('Error:', error);
    }
    };

    return {
        data,
        passwordMismatch,
        handleSubmit
    };
}
});
