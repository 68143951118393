import { NewsPost } from '../types';
import axiosInstance from './axiosInstance';

export async function fetchNewsPosts(): Promise<NewsPost[]> {
    try {
        // Check if the user is authenticated
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('User is not authenticated');
        }
    
        // Set the authentication token in the request header
        axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    
        // Send the API request to fetch news posts
        const response = await axiosInstance.get('/gonews/40');
        if (response.status !== 200) {
            throw new Error(`Failed to fetch news posts. Status code: ${response.status}`);
        }
    
        const data = response.data as { posts: NewsPost[] };
            return data.posts;
        } catch (error) {
            console.error('Error:', error);
            return [];
        }
}